<template>
  <div class="view pa24">
    <div class="d-flex">
      <div>
        <el-button class="ma" type="primary" @click="centerDialogVisible = true;upDate=1"
        >+ 新增类型</el-button
        >
      </div>
    </div>
    <commonTable
        :tableData="tableData"
        @handleSelectionChange="handleSelectionChange"
        :total="total"
        @handleCurrentChange="handleCurrentChange"
        @handleSizeChange="handleSizeChange"
        :currentPage="currentPage"
        :loading="loading"
    >
      <template v-slot:table>
        <el-table-column type="selection" />
        <el-table-column prop="typeName" align="center" label="类型名称" />
        <el-table-column align="center" label="排序">
          <template slot-scope="scope">
            <el-tooltip class="item" effect="dark" content="数值越大，排序越靠前" placement="right-start">
              <el-input v-model="scope.row.orderBy" min="0" @input="scope.row.orderBy=scope.row.orderBy.replace(/^0(0+)|[^\d]+/g,'')"  placeholder="数值越大，排序越靠前" @change="saveSort(scope.row)"/>
            </el-tooltip>
          </template>
        </el-table-column>
<!--        <el-table-column prop="createTime" align="center" label="创建时间" />-->
        <el-table-column align="center" label="操作">
          <template slot-scope="scope">
            <el-button type="primary" plain @click="open(scope.row)"
            >编辑</el-button
            >
            <customPopconfirm
                class="ma ml10 mb10"
                confirm-button-text="确定"
                cancel-button-text="取消"
                @confirm="del(scope.row.kfqTypeId)"
                title="确认删除？">
              <el-button type="danger" slot="reference" plain>删除</el-button>
            </customPopconfirm>
          </template>
        </el-table-column>
      </template>
    </commonTable>
    <el-dialog
        title="新增类型"
        :visible.sync="centerDialogVisible"
        @close="close"
        :modal-append-to-body="false"
        width="50%"
        center
    >
      <el-form
          ref="ruleForm" :model="ruleForm" :rules="rules" label-width="auto" class="view pa24" label-position="left"
      >
        <el-form-item prop="title" label="类型名称">
          <el-input
              clearable
              v-model="ruleForm.title"
              style="width: 100%"
              placeholder="请输入类型名称"
              maxlength="50"
              show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item label="绑定颜色" prop="colorID">
          <el-select
              v-model="ruleForm.colorID"
              style="width: 100%"
              placeholder="请绑定颜色"
              clearable
          >
            <el-option
                v-for="(item) in colorList"
                :key="item.mapColorId"
                :label="item.colorName"
                :value="item.mapColorId"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="排序" prop="sort">
          <el-input clearable v-model="ruleForm.sort" min="0" @input="ruleForm.sort=ruleForm.sort.replace(/^0(0+)|[^\d]+/g,'')" maxlength="11" show-word-limit></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="determine">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
import customPopconfirm from "@/components/common/customPopconfirm";
import {addKfqType,upKfqType,delKfqType,selectKfqTypePageList,selectMapColorPageList} from "@/api/serviceDevelopmentZone";
export default {
  name: "map_type",
  components: {
    commonTable,
    customPopconfirm
  },
  props:{
    jurisdiction:{
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      colorList:[],
      centerDialogVisible: false,
      ruleForm: {
        title:"",
        sort:"",
        colorID:''
      },
      dialogVisible: false,
      rules: {
        title: [{ required: true, message: "请输入类型名称", trigger: "blur" }],
        sort: [{ required: true, message: "请输入排序", trigger: "blur" }],
        colorID: [{ required: true, message: "请选择颜色", trigger: "blur" }],
      },
      tableData: [],
      filterName: "",
      row: "",
      delGoodsT: [],
      currentPage: 1,
      loading:false,
      total: 0, //总条数
      pageNum: 1,
      pageSize: 10,
      dialogImageUrl: "",
      upDate:1,
      kfqTypeId:"",
    };
  },
  computed: {

  },
  mounted(){
    this.getColorList()
    this.queryPage();
  },
  methods: {
    async getColorList() {
      let data = {
        associationId: localStorage.getItem("associationId"),
      };
      try {
        this.loading = true;
        const result = await selectMapColorPageList(data);
        this.loading = false;
        const { total, list } = result.data.pageInfo;
        this.colorList = list;
        this.total = total;
      } catch (error) {
        this.colorList = []
        this.loading = false;
      }
    },
    async queryPage() {
      let data = {
        pageSize: this.pageSize,
        pageNum: this.currentPage,
        associationId: localStorage.getItem("associationId"),
      };
      try {
        this.loading = true;
        const result = await selectKfqTypePageList(data);
        this.loading = false;
        const { total, list } = result.data.pageInfo;
        for (let i in list) {
          list[i].createTime = this.renderTime(list[i].createTime)
        }
        this.tableData = list;
        this.total = total;
      } catch (error) {
        this.tableData = []
        this.loading = false;
      }
    },
    renderTime(date) {
      var dateee = new Date(date).toJSON();
      return new Date(+new Date(dateee) + 8 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '')
    },
    handleSelectionChange(value) {
      this.delGoodsT = value;
    },
    /**@method 编辑
     * @param {Object} val - 当前点击行的值
     */
    open(val) {
      this.upDate = 2
      this.centerDialogVisible = true;
      let data = JSON.parse(JSON.stringify(val))
      this.kfqTypeId = data.kfqTypeId
      this.ruleForm.title = data.typeName;
      this.ruleForm.sort = data.orderBy;
      this.ruleForm.colorID = data.mapColorId
    },
    //删除弹窗
    async del(val) {
      try {
        await delKfqType({ kfqTypeId: val });
        await this.queryPage();
      } catch (error) {
        console.log(error);
      }
    },
    //当前页变更
    handleCurrentChange(val) {
      this.currentPage = val;
      this.queryPage();
    },
    //当前页查询数据条数变更
    handleSizeChange(val) {
      this.pageSize = val;
      this.queryPage();
    },
    /**@method 模态框关闭的回调 */
    close() {
      (this.ruleForm = {
        title:"",
        sort:"",
      }),
          this.centerDialogVisible = false;
    },
    determine(){
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          try {
            let data = {
              associationId: localStorage.getItem("associationId"),
              typeName:this.ruleForm.title,
              orderBy:this.ruleForm.sort,
              mapColorId:this.ruleForm.colorID
            };
            this.$message({
              message: "正在保存",
            });
            if (this.upDate == 1) {
              await addKfqType(data);
            }else {
              data.kfqTypeId = this.kfqTypeId
              await upKfqType(data);
            }
            this.centerDialogVisible = false;
            this.$message({
              message: "保存成功",
              type: "success",
            });
            await this.queryPage();
          } catch (error) {
            this.$message.closeAll();
            this.$message({
              message: error.message,
              type: "error",
            });
            this.centerDialogVisible = false;
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    handleRemove(file, fileList) {
      this.ruleForm.goodPhoto = fileList;
    },
    async saveSort(row){
      if(row && row.orderBy != ""){
        let ajax = {
          orderBy:row.orderBy,
          kfqTypeId:row.kfqTypeId
        }
        const result = await upKfqType(ajax);
        console.log("result:",result)
        this.$message({
          type: "success",
          message: "保存成功",
        });
        await this.queryPage();
      }else{
        this.$message({
          type: "error",
          message: "排序不能为空",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>





